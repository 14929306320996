.navbar {
  border-bottom: solid 1px #EBEBEB;
  background-color:  #FF5733;
}
.bottom-tab-nav {
  border-top: solid 1px #EBEBEB;
}
.nav-link,
.bottom-nav-link {
  color: #55575b;
}
.bottom-nav-link.active {
  color: #922c88;
}
.bottom-tab-label {
  font-size: 12px;
}

body {
  background-color: #FFC300 !important;
}

.main-container {
  padding-left: 25px;
  padding-right: 25px;
  overflow: hidden;
  overflow-x: hidden;
}

.girafHeader { 
  background-color: #573b1d !important;
  padding-top: 10px;
  padding-bottom: 10px;
}

.headerTitle {
  color: #000000;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-bottom: 0;
}

.nav-tabs {
    border-color: #573b1d !important;
}

.nav-link {
  color: #573b1d !important;
}

.nav-tabs .nav-link.active {
  background-color: #573b1d !important;
  color: #fff !important;
  border-color: #573b1d !important;
}

.dropdown-toggle::after {
  margin-left: 10px !important;
}

.btn-success {
  --bs-btn-bg: #573b1d !important;
  --bs-btn-border-color: #573b1d !important;
  --bs-btn-active-bg: #673b1d !important;
  --bs-btn-active-border-color: #673b1d !important;
  --bs-btn-hover-bg: #673b1d !important;
  --bs-btn-hover-border-color: #673b1d !important;
}

.btn {
  --bs-btn-bg: #573b1d !important;
  --bs-btn-border-color: #573b1d !important;
  --bs-btn-active-bg: #673b1d !important;
  --bs-btn-active-border-color: #673b1d !important;
  --bs-btn-hover-bg: #673b1d !important;
  --bs-btn-hover-border-color: #673b1d !important;
}

.form-select {
  color: #fff !important;
  border: 1px solid #573b1d !important;
  background-color: #573b1d !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e") !important;
}

.bhdLno{
  transform: rotate(-45deg) !important;
  }
